import gql from "graphql-tag";

export const DICTIONARY_GLOSSARY = gql`
  query {
    dictionary_glossary
  }
`;

export const GET_DICTIONARY = gql`
  query dictionary($alphabet: String, $options: read_options!, $query: String) {
    dictionary(alphabet: $alphabet, options: $options, query: $query) {
      error {
        message
        path
      }
      total_count
      words {
        definition
        id
        word
      }
    }
  }
`;

// CEBF09
