import React from 'react'
import 'assets/css/lfn.css'
import { Link } from "react-router-dom";
import styled from "styled-components";
import back from 'assets/img/back-act.svg'
import right from 'assets/img/act-right.svg'

const Content = styled.div`
  padding: 1rem 2.375rem;
  margin: 2.3125rem 0;
  border: none;
  border-radius: 5px;

  @media (max-width: 600px) {
    padding: 1rem 1rem;
  }
`;

export default function ActChapters() {
    return (
        <Content>
            <div>
                <div className="l--bet-box">
                    <div className="l--bet">
                        <p className="first-act">All</p>
                        <p>A</p>
                        <p>B</p>
                        <p>C</p>
                        <p>D</p>
                        <p>E</p>
                        <p>F</p>
                        <p>G</p>
                        <p>H</p>
                        <p>I</p>
                        <p>J</p>
                        <p>K</p>
                        <p>L</p>
                        <p>M</p>
                        <p>N</p>
                        <p>O</p>
                        <p>P</p>
                        <p>Q</p>
                        <p>R</p>
                        <p>S</p>
                        <p>T</p>
                        <p>U</p>
                        <p>V</p>
                        <p>W</p>
                        <p>X</p>
                        <p>Y</p>
                        <p>Z</p>
                    </div>
                </div>
                <Link to="/dashboard/federation/act" className="l-top-header">
                    <img src={back} />
                    <p style={{ marginLeft: 10 }}>Chapters</p>
                </Link>
                <div className="l--content">
                    <div className="l-top-header-2 none--phone">
                        <p>Section</p>
                        <p style={{ margin: 'auto' }}>Description</p>
                        {/* to balance things */}
                        <p style={{ marginLeft: 'auto' }}></p>
                    </div>
                    <div className="act--sec bock-phone">
                        <p>Section 1</p>
                        <p style={{ margin: 'auto' }}>Amendment of LN 33. of 1978</p>
                        <Link to="/dashboard/federation/act/chapters/one" style={{ marginLeft: 'auto' }} className="view--imp">View</Link>
                    </div>
                    <div className="act--sec imp-white bock-phone">
                        <p>Section 1</p>
                        <p style={{ margin: 'auto' }}>Amendment of LN 33. of 1978</p>
                        <Link to="/dashboard/federation/act/chapters/one" style={{ marginLeft: 'auto' }} className="view--imp">View</Link>
                    </div>
                    <div className="act--sec bock-phone">
                        <p>Section 1</p>
                        <p style={{ margin: 'auto' }}>Amendment of LN 33. of 1978</p>
                        <Link to="/dashboard/federation/act/chapters/one" style={{ marginLeft: 'auto' }} className="view--imp">View</Link>
                    </div>
                    <div className="act--sec imp-white bock-phone">
                        <p>Section 1</p>
                        <p style={{ margin: 'auto' }}>Amendment of LN 33. of 1978</p>
                        <Link to="/dashboard/federation/act/chapters/one" style={{ marginLeft: 'auto' }} className="view--imp">View</Link>
                    </div>
                </div>
            </div>
        </Content>
    )
}
