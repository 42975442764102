import React from "react";
import Loader from "react-loader-spinner";
import styled from "styled-components";

const PageLoaderBlock = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  svg {
    height: 70px !important;
    width: 70px !important;
  }
`;

const BlockLoaderWrapper = styled.div`
  height: 100%;
  display: flex;
  margin-bottom: 100px;
  margin-top: 100px;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
  svg {
    width: 46px;
    height: 46px;
  }
`;

// const PageLoaderBlock = styled.div`
// 	height: 100vh;
// 	display: flex;
// 	justify-content: center;
// 	align-items: center;
// 	position: absolute;
// 	width: 100%;
// 	top: 0;
// 	left: 0;
// 	svg {
// 		height: 70px !important;
// 		width: 70px !important;
// 	}
// `;

export const ButtonLoader = () => (
  <Loader
    type="Oval"
    color="white"
    width="20"
    height="20"
    style={{ display: "flex", justifyContent: "center" }}
  />
);

export const FileLoader = () => (
  <Loader type="ThreeDots" color="#7a8088" width="5" height="5" />
);

export const TripLoader = () => (
  <Loader type="ThreeDots" color="#7a8088" width="18" height="18" />
);

export const PageLoader = () => (
  <PageLoaderBlock>
    <Loader type="TailSpin" color="#2b7339" width="56" height="56" />
  </PageLoaderBlock>
);

export const BlockLoader = ({ marginTop, marginBottom }) => (
  <BlockLoaderWrapper
    style={{
      marginTop: marginTop || "20px",
      marginBottom: marginBottom || "20px",
    }}
  >
    <Loader type="TailSpin" color="#2b7339" width="46" height="46" />
  </BlockLoaderWrapper>
);

export const OverlayLoader = () => (
  <div id="lottie" className="uk-open uk-modal" data-uk-modal>
    <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical lottie-modal is-transparent">
      <div className="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <p>Updating Payment</p>
    </div>
  </div>
);
