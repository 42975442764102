import React, { createContext, useState, useCallback } from "react";
import { useLazyQuery } from "@apollo/client";
import {
  GET_ACTIVE_SUBSCRIPTION_DETAILS,
  GET_SUBS,
  PURCHASE_SUBSCRIPTION,
} from "schema/subscription";
import notify from "utils/toast";
import PropTypes from "prop-types";

export const SubscriptionContext = createContext();

export default function SubscriptionContextProvider({ children }) {
  const [
    getActiveSubscription,
    { loading: activeSubscriptionLoading, data: activeSubscription },
  ] = useLazyQuery(GET_ACTIVE_SUBSCRIPTION_DETAILS);
  const [
    getUserSubs,
    { loading: userSubsLoading, data: userSubs },
  ] = useLazyQuery(GET_SUBS);
  const [
    getSubscriptionPurchase,
    { loading: subscriptionPurchaseLoading, data: subscriptionPurchase },
  ] = useLazyQuery(PURCHASE_SUBSCRIPTION);

  const [userActiveSubscription, setUserActiveSubscription] = useState(null);
  const [userSubsBasedOnRole, setUserSubsBasedOnRole] = useState(null);
  const [userSubsPurchase, setUserSubsPurchase] = useState(null);

  const getActiveSubscriptionDetails = useCallback(() => {
    getActiveSubscription({
      variables: {},
    });
  }, []);

  const getUserSubsBasedOnRole = useCallback((userRole) => {
    getUserSubs({
      variables: {
        role: userRole,
      },
    });
  }, []);

  const getSubsPurchase = useCallback(
    (
      plan,
      provider = "paystack",
      redirect_url = `${window.location.href}`,
      region = "ng"
    ) => {
      getSubscriptionPurchase({
        variables: {
          info: {
            plan,
            region,
            redirect_url,
          },
          provider,
        },
      });
    },
    []
  );

  React.useEffect(() => {
    if (activeSubscription) {
      const {
        me: { data, error },
      } = activeSubscription;
      if (error) {
        if (error) {
          error.forEach((err) => {
            notify(err.message, "error");
          });
        }
      } else {
        setUserActiveSubscription(data);
      }
    }
  }, [activeSubscription]);

  React.useEffect(() => {
    if (userSubs) {
      const { getSubs } = userSubs;

      setUserSubsBasedOnRole(getSubs);
    }
  }, [userSubs]);

  React.useEffect(() => {
    if (subscriptionPurchase) {
      const {
        purchase_subscription: { data, error },
      } = subscriptionPurchase;
      if (error)
        error.forEach((err) => {
          notify(err.message, "error");
        });
      else setUserSubsPurchase(data);
    }
  }, [subscriptionPurchase]);

  return (
    <SubscriptionContext.Provider
      value={{
        getActiveSubscriptionDetails,
        activeSubscription,
        userActiveSubscription,
        getUserSubsBasedOnRole,
        activeSubscriptionLoading,
        userSubsLoading,
        userSubs,
        userSubsBasedOnRole,
        userSubsPurchase,
        subscriptionPurchaseLoading,
        getSubsPurchase,
      }}
    >
      {children}
    </SubscriptionContext.Provider>
  );
}

SubscriptionContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
