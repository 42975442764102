import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { LastLocationProvider } from "react-router-last-location";
import { PageLoader } from "components/Loaders";
import AuthLayout from "layouts/AuthLayout";
import DashboardLayout from "layouts/DashboardLayout";
import PrivateRoute from "./utils/PrivateRoute";
import AuthRoute from "./utils/AuthRoute";
import Error404 from "components/Error404";
import history from "utils/history";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, Slide } from "react-toastify";
import ComingSoon from "components/ComingSoon";
import BlankRoute from "./utils/BlankRoute";
import SecurityUpdateRoute from "./utils/SecurityUpdateRoute";

import PaymentCard from 'components/modal/PaymentCard'
import ActChapters from "pages/dashboard/lfn/ActChapters";
import ActSection from "pages/dashboard/lfn/ActSection";

// create Loadable pages
const Login = lazy(() => import("pages/auth/Login"));
const LoginToken = lazy(() => import("pages/auth/LoginToken"));
const WelcomeNotification = lazy(() =>
  import("pages/auth/WelcomeNotification")
);
const Register = lazy(() => import("pages/auth/Register"));
const ForgotPassword = lazy(() => import("pages/auth/ForgotPassword"));
const ResetPassword = lazy(() => import("pages/auth/ResetPassword"));
const SecurityUpdate = lazy(() => import("pages/auth/SecurityUpdate"));
const SecurityUpdateComplete = lazy(() =>
  import("pages/auth/SecurityUpdateComplete")
);

const Dashboard = lazy(() => import("pages/dashboard"));
const Judgements = lazy(() => import("pages/dashboard/judgments/Judgements"));
const SearchResult = lazy(() => import("pages/dashboard/search/SearchResult"));
const Cases = lazy(() => import("pages/dashboard/cases/Cases"));
const CourtCases = lazy(() => import("pages/dashboard/cases/CourtCases"));
const Case = lazy(() => import("pages/dashboard/cases/Case"));
const Settings = lazy(() => import("pages/dashboard/settings/Settings"));
const Subscription = lazy(() =>
  import("pages/dashboard/subscription/SubscriptionPage")
);

/**
 * const Constitution = lazy(() =>
 import("pages/dashboard/constitution/Constitution")
 );
 const Sections = lazy(() => import("pages/dashboard/constitution/Sections"));
 const Chapters = lazy(() => import("pages/dashboard/constitution/Chapters"));
 const Chapter = lazy(() => import("pages/dashboard/constitution/Chapter"));
 const Schedule = lazy(() => import("pages/dashboard/constitution/Schedule"));
 const Schedules = lazy(() => import("pages/dashboard/constitution/Schedules"));
 const Paragraphs = lazy(() =>
 import("pages/dashboard/constitution/Paragraphs")
 );
 * @type {React.LazyExoticComponent<React.ComponentType<any>>}
 */

const Indexes = lazy(() => import("pages/dashboard/indexes/Indexes"));
const CasesIndexesByYear = lazy(() =>
  import("pages/dashboard/indexes/Case.Index.Year")
);

const SubjectMatterIndexesByYear = lazy(() =>
  import("pages/dashboard/indexes/Subject-Matter.Index.Year")
);

const CasesIndexesByRecent = lazy(() =>
  import("pages/dashboard/indexes/Cases.Index.Recent")
);

const IndexesByVolumes = lazy(() =>
  import("pages/dashboard/indexes/Volume.Index")
);

const MixedTypeViewInVolume = lazy(() =>
  import("pages/dashboard/indexes/Volume.Indexes.Main")
);

// const Blogs = lazy(() => import("pages/dashboard/blogs/Blogs"));
// const ComingSoon = lazy(() => import('..'));
// const ComingSoon = lazy(() => import('../components/ComingSoon'));
// const Blog = lazy(() => import("pages/dashboard/blogs/Blog"));

const Dictionary = lazy(() => import("pages/dashboard/dictionary/Dictionary"));

const Lfn = lazy(() => import("pages/dashboard/lfn/Lfn"));

const Act = lazy(() => import("pages/dashboard/lfn/Act"));

const Routes = () => {
  return (
    <React.Fragment>
      <Router basename={process.env.PUBLIC_URL} history={history}>
        <LastLocationProvider>
          <Suspense fallback={<PageLoader />}>
            <Switch>
              {/* can't access them when you are logged in */}
              <AuthRoute exact path="/" component={Login} layout={AuthLayout} />

              <BlankRoute
                exact
                path="/login/:token"
                component={LoginToken}
                layout={AuthLayout}
              />

              <AuthRoute
                exact
                path="/login"
                component={Login}
                layout={AuthLayout}
              />

              <AuthRoute
                exact
                path="/register"
                component={Register}
                layout={AuthLayout}
              />

              <AuthRoute
                exact
                path="/forgot-password"
                component={ForgotPassword}
                layout={AuthLayout}
              />

              <SecurityUpdateRoute
                exact
                path="/security/update"
                component={SecurityUpdate}
                layout={AuthLayout}
              />

              <AuthRoute
                exact
                path="/reset-password/:key"
                component={ResetPassword}
                layout={AuthLayout}
              />

              <SecurityUpdateRoute
                exact
                path="/security/update/:key"
                component={SecurityUpdateComplete}
                layout={AuthLayout}
              />
              {/* Private Route */}
              <PrivateRoute
                exact
                path="/welcome"
                component={WelcomeNotification}
                layout={AuthLayout}
              />

              <PrivateRoute
                exact
                path="/dashboard/judgements"
                component={Judgements}
                layout={DashboardLayout}
              />
              <PrivateRoute
                exact
                path="/dashboard"
                component={Dashboard}
                layout={DashboardLayout}
              />

              <PrivateRoute
                exact
                path="/dashboard/result"
                component={SearchResult}
                layout={DashboardLayout}
              />

              <PrivateRoute
                exact
                path="/dashboard/cases/:court_id/:case_id"
                component={Case}
                layout={DashboardLayout}
              />
              <PrivateRoute
                exact
                path="/dashboard/cases/:court_id"
                component={CourtCases}
                layout={DashboardLayout}
              />
              <PrivateRoute
                exact
                path="/dashboard/cases"
                component={Cases}
                layout={DashboardLayout}
                title={"Cases"}
              />
              <PrivateRoute
                exact
                path="/dashboard/settings"
                component={Settings}
                layout={DashboardLayout}
                title={"Settings"}
              />

              <PrivateRoute
                exact
                path="/dashboard/constitutions"
                component={ComingSoon}
                layout={DashboardLayout}
                title={'"Civil Procedure Rules'}
              />

              {/*<PrivateRoute*/}
              {/*  exact*/}
              {/*  path="/dashboard/constitutions/sections"*/}
              {/*  component={Sections}*/}
              {/*  layout={DashboardLayout}*/}
              {/*  title={'"Civil Procedure Rules'}*/}
              {/*/>*/}

              {/*<PrivateRoute*/}
              {/*  exact*/}
              {/*  path="/dashboard/constitutions/chapters"*/}
              {/*  component={Chapters}*/}
              {/*  layout={DashboardLayout}*/}
              {/*  title={'"Civil Procedure Rules'}*/}
              {/*/>*/}

              {/*<PrivateRoute*/}
              {/*  exact*/}
              {/*  path="/dashboard/constitutions/chapter/one"*/}
              {/*  component={Chapter}*/}
              {/*  layout={DashboardLayout}*/}
              {/*  title={'"Civil Procedure Rules'}*/}
              {/*/>*/}

              {/*<PrivateRoute*/}
              {/*  exact*/}
              {/*  path="/dashboard/constitutions/schedules"*/}
              {/*  component={Schedules}*/}
              {/*  layout={DashboardLayout}*/}
              {/*  title={'"Civil Procedure Rules'}*/}
              {/*/>*/}

              {/*<PrivateRoute*/}
              {/*  exact*/}
              {/*  path="/dashboard/constitutions/schedule/one"*/}
              {/*  component={Schedule}*/}
              {/*  layout={DashboardLayout}*/}
              {/*  title={'"Civil Procedure Rules'}*/}
              {/*/>*/}

              {/*<PrivateRoute*/}
              {/*  exact*/}
              {/*  path="/dashboard/constitutions/paragraphs"*/}
              {/*  component={Paragraphs}*/}
              {/*  layout={DashboardLayout}*/}
              {/*  title={'"Civil Procedure Rules'}*/}
              {/*/>*/}

              <PrivateRoute
                exact
                path="/dashboard/indexes"
                component={Indexes}
                layout={DashboardLayout}
                title={"Indexes"}
              />

              <PrivateRoute
                exact
                path="/dashboard/indexes/cases/year"
                component={CasesIndexesByYear}
                layout={DashboardLayout}
                title={"Indexes"}
              />

              <PrivateRoute
                exact
                path="/dashboard/indexes/cases/recent"
                component={CasesIndexesByRecent}
                layout={DashboardLayout}
                title={"Indexes"}
              />

              <PrivateRoute
                exact
                path="/dashboard/indexes/subject_matter"
                component={SubjectMatterIndexesByYear}
                layout={DashboardLayout}
                title={"Indexes"}
              />

              <PrivateRoute
                exact
                path="/dashboard/indexes/volumes"
                component={IndexesByVolumes}
                layout={DashboardLayout}
                title={"Indexes"}
              />

              <PrivateRoute
                exact
                path="/dashboard/indexes/volume/:vol"
                component={MixedTypeViewInVolume}
                layout={DashboardLayout}
                title={"Indexes"}
              />

              <PrivateRoute
                exact
                path="/dashboard/federation"
                component={Lfn}
                layout={DashboardLayout}
                title={"Civil Procedure Rules"}
              />

              <PrivateRoute
                exact
                path="/dashboard/federation/act"
                component={Act}
                layout={DashboardLayout}
                title={"Civil Procedure Rules"}
              />

              <PrivateRoute
                exact
                path="/dashboard/federation/act/chapters"
                component={ActChapters}
                layout={DashboardLayout}
                title={"Civil Procedure Rules"}
              />

              <PrivateRoute
                exact
                path="/dashboard/federation/act/chapters/one"
                component={ActSection}
                layout={DashboardLayout}
                title={"Civil Procedure Rules"}
              />

              <PrivateRoute
                exact
                path="/dashboard/procedures"
                component={ComingSoon}
                layout={DashboardLayout}
                title={"Civil Procedure Rules"}
              />

              <PrivateRoute
                exact
                path="/dashboard/dictionary"
                component={Dictionary}
                layout={DashboardLayout}
                title={"Law Dictionary"}
              />

              <PrivateRoute
                exact
                path="/dashboard/blogs"
                component={ComingSoon}
                layout={DashboardLayout}
              />

              {/*<PrivateRoute*/}
              {/*  exact*/}
              {/*  path="/dashboard/blog"*/}
              {/*  component={Blog}*/}
              {/*  layout={DashboardLayout}*/}
              {/*/>*/}

              <PrivateRoute
                exact
                path="/dashboard/notes"
                component={ComingSoon}
                layout={DashboardLayout}
              />

              <PrivateRoute
                exact
                path="/dashboard/subscriptions"
                component={Subscription}
                layout={DashboardLayout}
                title={"Subscriptions"}
              />
              <PrivateRoute
                exact
                path="/dashboard/p-card"
                component={PaymentCard}
                layout={DashboardLayout}
              />
              {/* catch all invalid urls */}
              <Route component={Error404} />
            </Switch>
          </Suspense>
        </LastLocationProvider>
      </Router>
      <ToastContainer autoClose={3000} pauseOnHover={true} transition={Slide} />
    </React.Fragment>
  );
};

export default Routes;
