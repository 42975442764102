import React from "react";
import styled from "styled-components";
import unavailable from "assets/img/unavailable.png";
import { Button } from "components/Styles";
import { FiArrowLeft } from "react-icons/fi";

const Wrapper = styled.div`
  height: ${({ height }) => height || "100%"};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-display: column;
  flex-direction: column;
  p {
    color: #27750a !important;
    font-size: 0.9rem;
    line-height: 1.7;
    text-align: center;
    max-width: 400px;
    line-height: 1.7;
    margin: 15px 0 25px;
  }
  img {
    width: 50%;
    margin-top: 10px;
  }
`;

const ComingSoon = ({ history }) => {
  return (
    <Wrapper>
      <img src={unavailable} />
      <h4>OBJECTION</h4>
      <p>Our engineers are still working on this page.</p>
      <Button
        onClick={() => {
          history.goBack();
          // window.location.reload();
        }}
      >
        <FiArrowLeft style={{ marginRight: "20px" }} />
        Go Back
      </Button>
    </Wrapper>
  );
};

export default ComingSoon;
