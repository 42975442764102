import React from "react";
import styled from "styled-components";
import { AuthContext } from "context/Auth";
import { useHistory } from "react-router-dom";
import searchIcon from "assets/img/search.svg";
import UserAvatar from "react-user-avatar";
import dropDown from "assets/img/drop-down.svg";
import { Link } from "react-router-dom";
import { LogOut, Settings, Menu } from "react-feather";
import { SearchContext } from "context/Search";
import pic from 'assets/img/pic.svg'

const DropDown = styled.ul`
  z-index: 20;
  position: absolute;
  display: flex;
  flex-direction: column;
  background: #fff;
  border: 1px rgba(242, 48, 80, 0.1) solid;
  border-radius: 8px;
  border-bottom: none;
  box-shadow: 0px 5px 5px rgba(242, 48, 80, 0.3);
  width: 180px;
  display: none;
  border-top: 10px solid rgba(255, 255, 255, 0);
  right: 2px;

  a {
    display: flex;
    flex-direction: row;
    padding: 10px;
    border-bottom: 1px solid rgba(105, 196, 72, 0.2);
    z-index: 20;
    font-family: montserrat, sans-serif;

    link {
      color: $gs3-secondary;
      background: #fff;
      padding: 10px;
      transition: background-color ease-in-out 0.2s;
    }

    &:hover {
      background-color: rgba(105, 196, 72, 0.2);
    }
  }
`;

const DropDownWrapper = styled.div`
  position: relative;
  z-index: 30;

  img {
    cursor: pointer;
  }

  &:hover > ul,
  & > ul:hover {
    display: block;
  }

  ${"" /* @keyframes profileSideDropDown {
    0% {
      transform: translateY(0em);
      top: 0px;
      opacity: 0;
    }

    100% {
      opacity: 1;
      transform: translateY(0.9em);
      top: 40px;
      transition: 0.5s;
    }
  } */}
`;

function runSearch(history, setTab, setQuery, tab, search) {
  if (search && history.location.pathname === "/dashboard/dictionary") {
    setTab("dictionary");
    setQuery(search);
  } else {
    setTab("cases");
    history.push(`/dashboard/result?q=${search}&tab=cases`);
  }
}


export default function Header({ openSidebar }) {
  const history = useHistory();
  const query = new URLSearchParams(history.location.search).get("q");
  const { logout, me, meQuery } = React.useContext(AuthContext);
  const { tab, setQuery, setTab } = React.useContext(SearchContext);
  const [search, setSearch] = React.useState(query || "");

  React.useEffect(() => meQuery(), []);


  return (
    <nav>
      <div className="inner-container">
        <div id="nav-items">
          <Menu
            id="sidenav-icon"
            className="mobile-only"
            alt=""
            onClick={(e) => {
              openSidebar(e);
            }}
          />
          <Link to="/" className="brand-phone">
            <img className="" style={{ width: 50, height: 50 }} src={pic} />
          </Link>
          <form className="desktop-only" onSubmit={(e) => e.preventDefault()}>
            <div className="input">
              <input
                type="text"
                name="search"
                id="search"
                placeholder="search wrn"
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                onKeyUp={(e) => {
                  if (e.key === "Enter" && search) {
                    runSearch(history, setTab, setQuery, tab, search);
                  }
                }}
                value={search}
              />
              <img
                src={searchIcon}
                alt="searchIcon"
                onClick={() => {
                  if (search) runSearch(history, setTab, setQuery, tab, search);
                }}
              />
            </div>
            {history?.location?.pathname === "/dashboard/result" && (
              <div id="search-suggestion-box">
                <div
                  onClick={() => {
                    setTab("cases");
                    setQuery(search);
                    history.push(`/dashboard/result?q=${search}&tab=cases`);
                  }}
                  className="search-suggestion"
                >
                  <p>Case</p>
                </div>

                <div
                  onClick={() => {
                    if (search) {
                      setTab("dictionary");
                      setQuery(search);
                      history.push(`/dashboard/dictionary`);
                    }
                  }}
                  className="search-suggestion"
                >
                  <p>Dictionary</p>
                </div>
              </div>
            )}
          </form>
          <div id="right-nav-items" style={{ marginLeft: "auto" }}>
            <div id="profile" style={{ display: "flex", alignItems: "center" }}>
              <React.Fragment>
                <UserAvatar
                  size="40"
                  style={{
                    color: "white",
                    fontWeight: "400",
                    userSelect: "none",
                    marginRight: "5px",
                    border: "8px solid #ffebf4",
                    borderRadius: "50%",
                  }}
                  name={
                    me?.first_name
                      ? `${me?.first_name} ${me?.last_name}`
                      : "Anonymous"
                  }
                  src={me?.profile_pic}
                />
              </React.Fragment>

              <DropDownWrapper>
                <img src={dropDown} alt="" />
                <DropDown
                  className="dropdown"
                  style={{
                    boxShadow: "0px 2px 24px rgba(235, 232, 237, 0.6)",
                    overflow: "hidden",
                    border: "1px solid rgba(255, 0, 114, 0.08)",
                  }}
                >
                  <div style={{ padding: "1rem" }}>
                    <p style={{ fontSize: 16, fontWeight: "400" }}>
                      Signed in as
                    </p>
                    <p
                      style={{ fontSize: 16, fontWeight: "700" }}
                    >{`${me?.first_name} ${me?.last_name}`}</p>
                    <p style={{ fontSize: 14, marginTop: 2 }}>
                      ({me?.role_?.role})
                    </p>
                  </div>
                  <Link to="/dashboard/settings">
                    <div
                      id="link"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <div
                        style={{
                          marginRight: "10px",
                        }}
                      >
                        <Settings size={20} />
                      </div>
                      <p className="text-base">Settings</p>
                    </div>
                  </Link>
                  <Link onClick={() => logout({ variables: { type: "" } })}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div
                        style={{
                          marginRight: "10px",
                        }}
                      >
                        <LogOut size={20} />
                      </div>
                      <p className="text-base">Logout</p>
                    </div>
                  </Link>
                </DropDown>
              </DropDownWrapper>
            </div>
          </div>
        </div>
        {/*MOBILE TABS*/}
        <form className="mobile-only-form" onSubmit={(e) => e.preventDefault()}>
          <div className="input">
            <input
              type="text"
              name="search"
              id="search"
              placeholder="search"
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              onKeyUp={(e) => {
                if (e.key === "Enter" && search) {
                  runSearch(history, setTab, setQuery, tab, search);
                }
              }}
              value={search}
            />
          </div>
          {history?.location?.pathname === "/dashboard/result" && (
            <div>
              <div id="search-suggestion-box">
                <div
                  onClick={() => {
                    setTab("cases");
                    setQuery(search);
                    history.push(`/dashboard/result?q=${search}&tab=cases`);
                  }}
                  className="search-suggestion"
                >
                  <p>Case</p>
                </div>

                <div
                  onClick={() => {
                    if (search) {
                      setTab("dictionary");
                      setQuery(search);
                      history.push(`/dashboard/dictionary`);
                    }
                  }}
                  className="search-suggestion"
                >
                  <p>Dictionary</p>
                </div>
              </div>
            </div>
          )}
        </form>
      </div>
    </nav>
  );
}
