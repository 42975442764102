import React, { createContext, useCallback } from "react";
import { useLazyQuery } from "@apollo/client";
import notify from "utils/toast";
import { GET_JUDGEMENT_LIST, GET_CASES } from "schema/judgement";

export const CaseContext = createContext();

const CaseContextProvider = ({ children }) => {
  const [
    getSupremeCases,
    { loading: supremeLoading, data: supremeCases },
  ] = useLazyQuery(GET_JUDGEMENT_LIST);

  const [
    getAppealCases,
    { loading: appealLoading, data: appealCases },
  ] = useLazyQuery(GET_JUDGEMENT_LIST);
  const [getCase, { loading: caseLoading, data: judgementCase }] = useLazyQuery(
    GET_CASES
  );
  const [cases, setCases] = React.useState({});
  const [judgement, setJudgement] = React.useState({});
  const [count, setCount] = React.useState(0);

  const getCases = useCallback((court_category = 1, skip = 0, take = 4) => {
    if (parseInt(court_category) === 2) {
      getSupremeCases({
        variables: {
          court_category: 2,
          region: "ng",
          options: {
            skip,
            take,
            order: {
              key: "volume",
              value: -1,
            },
          },
        },
      });
    } else {
      getAppealCases({
        variables: {
          court_category: 1,
          region: "ng",
          options: {
            skip,
            take,
            order: {
              key: "volume",
              value: -1,
            },
          },
        },
      });
    }
  }, []);

  React.useEffect(() => {
    if (supremeCases) {
      const {
        judgements_list: { data, error, total_count },
      } = supremeCases;

      setCases((cases) => ({
        ...cases,
        supremeCases: {
          cases: data,
          count: total_count,
        },
      }));

      if (error) {
        error.forEach((err) => {
          notify(err.message, "error");
        });
      }
    }
    if (appealCases) {
      const {
        judgements_list: { data, error, total_count },
      } = appealCases;

      setCases((cases) => ({
        ...cases,
        appealCases: {
          cases: data,
          count: total_count,
        },
      }));

      if (error) {
        error.forEach((err) => {
          notify(err.message, "error");
        });
      }
    }
  }, [supremeCases, appealCases]);

  React.useEffect(() => {
    if (judgementCase) {
      const {
        case_details: { data, error },
      } = judgementCase;
      if (error) {
        if (error) {
          error.forEach((err) => {
            notify(err.message, "error");
          });
        }
      } else {
        setJudgement(data);
      }
    }
  }, [judgementCase]);

  const getSingleCase = useCallback(async (id) => {
    getCase({
      variables: {
        case_id: id,
        region: localStorage.country_code || "ng",
      },
    });
  }, []);

  return (
    <CaseContext.Provider
      value={{
        count,
        cases,
        supremeLoading,
        appealLoading,
        caseLoading,
        getCases,
        judgement,
        getSingleCase,
      }}
    >
      {children}
    </CaseContext.Provider>
  );
};

export default CaseContextProvider;
