import React, { createContext } from "react";
import { useLazyQuery } from "@apollo/client";
import { SEARCH } from "schema/searches";

export const SearchContext = createContext({});

export default function SearchContextProvider({ children }) {
  const [search, { data, loading }] = useLazyQuery(SEARCH);
  const [_query_, setQuery] = React.useState("");
  const [tab, setTab] = React.useState("cases");
  /*cases | lfn | cpr | cons -- Tabs*/

  return (
    <SearchContext.Provider
      value={{ search, data, loading, tab, setTab, _query_, setQuery }}
    >
      {children}
    </SearchContext.Provider>
  );
}
