import gql from "graphql-tag";
// Search
export const SEARCH = gql`
  query search(
    $query: String!
    $filters: SearchFilters
    $options: read_options!
    $tab: SearchTab
    $region: Region
  ) {
    search(
      query: $query
      filters: $filters
      options: $options
      tab: $tab
      region: $region
    ) {
      total_count
      cases {
        case_id
        court_id
        party_1
        party_2
        party_1_title
        party_2_title
        volume
        facts_and_history
        date
      }
      words {
        id
        definition
        word
      }
    }
  }
`;
// Search

export const SEARCH_EXTRA_CC = gql`
  query se_cc($region: Region!) {
    se_court_categories(region: $region) {
      court_id
      court_category
    }
  }
`;
export const SEARCH_EXTRA_JUSTICES = gql`
  query se_justices($region: Region!) {
    se_justices(region: $region) {
      justice_id
      name
    }
  }
`;
export const SEARCH_EXTRA_RATIOS = gql`
  query se_ratios($case_id: Int!, $region: Region!) {
    se_ratios(case_id: $case_id, region: $region) {
      link_per
      summary
    }
  }
`;
export const SEARCH_EXTRA_SUBJECT_MATTER = gql`
  query se_subject_matter($case_id: Int!, $region: Region!) {
    se_subject_matter(case_id: $case_id, region: $region) {
      description
      heading
    }
  }
`;
