import gql from "graphql-tag";

export const GET_ACTIVE_SUBSCRIPTION_DETAILS = gql`
  query {
    me {
      ... on T_response {
        ok
        message
        status
        error {
          path
          message
        }
      }
      ... on me {
        ok
        message
        status
        data {
          user_id
          active_subscriptions {
            id
            start
            end
            subscription_reference
            subscription_plan {
              name
              subscription_plan_id
              active
              amount
              description
              domain
              feature_list
              plan_code
              interval
              provider
              sub_plan_class {
                name
              }
            }
          }

          subscription_plan_invoice {
            subscription_plan_invoice_id
            subscription_reference

            completed
            provider_response
            date
            region
            currency
            start
            end
            subscription_plan {
              subscription_plan_id
              active
              amount
              description
              domain
              feature_list
              plan_code
              interval
              provider
            }
          }
        }
      }
    }
  }
`;

export const GET_SUBS = gql`
  query get_subs_for_user($role: FeaturedRole!) {
    getSubs(role: $role) {
      name
      amount
      interval
      description
      plan_code
      feature_list
      provider
    }
  }
`;

export const PURCHASE_SUBSCRIPTION = gql`
  query purchase_subscription(
    $info: purchase_info!
    $provider: SubscriptionProviders!
  ) {
    purchase_subscription(info: $info, provider: $provider) {
      ok
      status
      message
      data {
        access_code
        authorization_url
        reference
      }
      error {
        message
        path
      }
    }
  }
`;
