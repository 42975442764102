import React from "react";
import { Route, Redirect } from "react-router-dom";
import { AuthContext, goToPath } from "context/Auth";
import { useLastLocation } from "react-router-last-location";
import history from "utils/history";

export default function AuthRoute({
  layout: Layout,
  component: Component,
  auth,
  title,
  ...rest
}) {
  const { isAuthenticated } = React.useContext(AuthContext);
  const lastLocation = useLastLocation();
  const redirectUrl = new URLSearchParams(history.location.search).get(
    "redirectUrl"
  );

  // console.log("lastLocation", lastLocation.pathname.substr(1));
  if (lastLocation && lastLocation.pathname && !redirectUrl)
    goToPath(`/login?redirectUrl=${lastLocation.pathname.substr(1)}`);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!isAuthenticated)
          return (
            <Layout title={title}>
              <Component {...props} />
            </Layout>
          );
        else return <Redirect to="/dashboard" />;
      }}
    />
  );
}
