import React from "react";
import SideNav from "components/SideNav";
import Header from "components/Header";

const DashboardLayout = ({ children }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  let sidebarRef = React.useRef("");
  let overlayRef = React.useRef("");

  const openSidebar = (e) => {
    sidebarRef.current.style.transform = "translateX(0)";

    overlayRef.current.style.opacity = 1;

    overlayRef.current.style.zIndex = 9998;

    // e.target.style.opacity = 0;

    setIsOpen(true);
  };

  const closeSidebar = () => {
    if (isOpen) {
      overlayRef.current.opacity = 0;

      overlayRef.current.style.zIndex = -1;

      sidebarRef.current.style.transform = "translateX(-245px)";

      setIsOpen(false);
    }
  };

  return (
    <div className="container">
      <SideNav sidebarRef={sidebarRef} closeSidebar={closeSidebar} />

      <div
        className="overlay"
        id="overlay"
        ref={overlayRef}
        onClick={() => {
          closeSidebar();
        }}
      />
      <div id="main">
        <Header openSidebar={openSidebar} isOpen={isOpen} />
        <div id="body">{children}</div>
      </div>
    </div>
  );
};

export default DashboardLayout;
