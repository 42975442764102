import React from "react";
import PropTypes from "prop-types";

import AuthContextProvider from "./Auth";
import CaseContextProvider from "./Cases/index";
import SubscriptionContextProvider from "./Subscriptions";
import IndexesContextProvider from "./Indexes";
import SearchContextProvider from "./Search";
import DictionaryContextProvider from "./Dictionary";

const AppContextProvider = ({ children }) => {
  return (
    <AuthContextProvider>
      <CaseContextProvider>
        <SubscriptionContextProvider>
          <IndexesContextProvider>
            <SearchContextProvider>
              <DictionaryContextProvider>{children}</DictionaryContextProvider>
            </SearchContextProvider>
          </IndexesContextProvider>
        </SubscriptionContextProvider>
      </CaseContextProvider>
    </AuthContextProvider>
  );
};

export default AppContextProvider;

AppContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
