import React from "react";
import { Route } from "react-router-dom";
import { AuthContext } from "context/Auth";

/**
 * [x] clean authentication
 * [x] run security update
 */

export default function SecurityUpdateRoute({
  layout: Layout,
  component: Component,
  title,
  ...rest
}) {
  const { setIsAuthenticated } = React.useContext(AuthContext);
  if (localStorage.wrn_user) {
    localStorage.removeItem("wrn_user");
    localStorage.removeItem("wrn_user_dashboard_token");
    setIsAuthenticated(false);
  }

  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout title={title}>
          <Component {...props} />
        </Layout>
      )}
    />
  );
}
