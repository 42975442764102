import gql from "graphql-tag";

export default gql`
  query index_board_info($region: Region!) {
    index_board_info(region: $region) {
      cases
      subject_matter
      volumes
      years
    }
  }
`;

export const CASE_GLOSSARY = gql`
  query case_glossary($region: Region!, $volume: String, $year: String) {
    case_glossary(region: $region, volume: $volume, year: $year)
  }
`;

export const SUBJECT_MATTER_GLOSSARY = gql`
  query subject_matter_glossary(
    $region: Region!
    $volume: String
    $year: String!
  ) {
    subject_matter_glossary(region: $region, volume: $volume, year: $year)
  }
`;

export const INDEXED_CASES_BY_YEAR = gql`
  query indexed_cases_by_year(
    $options: read_options!
    $order: String
    $region: Region!
    $year: String!
  ) {
    indexed_cases_by_year(
      options: $options
      region: $region
      year: $year
      order: $order
    ) {
      total_count
      error {
        message
        path
      }
      icases_of_year {
        case_id
        party_1
        party_2
        title
        formatted_date
        case_no
        court_id
        date
        description
        section
        volume
      }
    }
  }
`;

export const INDEXED_CASES_BY_RECENT = gql`
  query indexed_cases_by_recent(
    $options: read_options!
    $order: String
    $region: Region!
  ) {
    indexed_cases_by_recent(options: $options, region: $region, order: $order) {
      total_count
      error {
        message
        path
      }
      icase_of_recent {
        case_id
        party_1
        party_2
        title
        case_no
        court_id
        date
        description
        formatted_date
        section
        volume
      }
    }
  }
`;

export const COUNT_CASES_IN_A_VOLUME = gql`
  query count_cases_in_a_volume(
    $options: read_options!
    $region: Region!
    $volume: String!
  ) {
    get_cases_in_a_volume(options: $options, region: $region, volume: $volume) {
      total_count
    }
  }
`;

export const GET_VOLUMES_IN_YEAR = gql`
  query get_volumes_in_a_year(
    $options: read_options!
    $region: Region!
    $year: String!
  ) {
    indexed_volumes_by_year(options: $options, region: $region, year: $year) {
      total_count
      iv {
        volume
      }
      error {
        message
        path
      }
    }
  }
`;

export const GET_CASES_IN_A_VOLUME = gql`
  query get_cases_in_a_volume(
    $options: read_options!
    $order: String
    $region: Region!
    $volume: String!
  ) {
    get_cases_in_a_volume(
      options: $options
      region: $region
      volume: $volume
      order: $order
    ) {
      total_count
      icases_of_volume {
        case_id
        party_1
        party_2
        title
        formatted_date
        court_id
        case_no
        volume
        date
        description
        section
      }
      error {
        message
        path
      }
    }
  }
`;

export const GET_SM_IN_A_VOLUME = gql`
  query get_subject_matter_in_a_volume(
    $options: read_options!
    $order: String
    $region: Region!
    $volume: String!
  ) {
    get_subject_matter_in_a_volume(
      options: $options
      order: $order
      region: $region
      volume: $volume
    ) {
      total_count
      ism_from_volume {
        case_id
        court_id
        heading
        formatted_date
        party_1
        party_2
        title
        description
        volume
      }
      error {
        path
        message
      }
    }
  }
`;

export const INDEXED_SM = gql`
  query indexed_subject_matter(
    $options: read_options!
    $order: String
    $region: Region!
    $year: String!
  ) {
    indexed_subject_matter(
      options: $options
      order: $order
      region: $region
      year: $year
    ) {
      total_count
      ism_from_year {
        case_id
        court_id
        date
        description
        formatted_date
        heading
        party_1
        party_2
        title
        volume
      }
      error {
        message
        path
      }
    }
  }
`;
