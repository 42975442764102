import jwt_decode from "jwt-decode";

export const decodeToken = (token) => {
  return jwt_decode(token);
};

export const isTokenValid = () => {
  const current_time = Date.now() / 1000;
  // Check for token
  const token = localStorage.getItem("wrn_user_dashboard_token");
  if (token) {
    const decoded = decodeToken(token);
    return decoded.exp >= current_time;
  } else return false;
};
