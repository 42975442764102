import React, { createContext } from "react";
import { useLazyQuery } from "@apollo/client";
import { DICTIONARY_GLOSSARY, GET_DICTIONARY } from "../../schema/dictionary";

export const DictionaryContext = createContext({});

export default function DictionaryContextProvider({ children }) {
  const [
    glossary,
    { data: glossaryData, loading: glossaryLoading },
  ] = useLazyQuery(DICTIONARY_GLOSSARY);

  const [
    dictionary,
    { data: dictionaryData, loading: dictionaryLoading },
  ] = useLazyQuery(GET_DICTIONARY);

  return (
    <DictionaryContext.Provider
      value={{
        glossary,
        glossaryData,
        glossaryLoading,
        dictionary,
        dictionaryData,
        dictionaryLoading,
      }}
    >
      {children}
    </DictionaryContext.Provider>
  );
}
