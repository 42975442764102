import React, { createContext, useState, useEffect } from "react";
import {
  REGISTER_MUTATION,
  LOGIN_MUTATION,
  GET_USER,
  LOGOUT_MUTATION,
  UPDATE_USER,
  UPDATE_PASSWORD,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  SECURITY_UPDATE,
} from "schema/auth";
import { useLazyQuery, useMutation } from "@apollo/client";
import notify from "utils/toast";
import { isEmpty } from "lodash";
import history from "utils/history";
import { decodeToken } from "../../utils/auth";
import Swal from "sweetalert2";

export const AuthContext = createContext({});

export function goToPath(path) {
  window.location = path;
  // window.location.reload();
}

export function quickExit() {
  // exit without deleting server session & redirect
  localStorage.removeItem("wrn_user");
  localStorage.removeItem("wrn_user_dashboard_token");
  setTimeout(() => window.location.reload(), 1000);
  window.location = "/login";
}

export default function AuthContextProvider({ children }) {
  const user = localStorage?.wrn_user ? JSON.parse(localStorage?.wrn_user) : {};
  const [isAuthenticated, setIsAuthenticated] = useState(!isEmpty(user));
  const [me, setMe] = useState(user);
  const [login, { data: LoginData, loading: loadingLogin }] = useMutation(
    LOGIN_MUTATION
  ); // error
  const [meQuery, { data: meData, loading: meLoading }] = useLazyQuery(
    GET_USER
  );
  const [
    updateUser,
    { data: updateData, loading: updateLoading },
  ] = useMutation(UPDATE_USER);
  const [
    updatePassword,
    { data: passwordData, loading: passwordLoading },
  ] = useMutation(UPDATE_PASSWORD);
  const [logout, { data: logoutData, loading: logoutLoading }] = useMutation(
    LOGOUT_MUTATION
  );
  const [
    register,
    { loading: registerLoading, data: registerData },
  ] = useMutation(REGISTER_MUTATION);

  const [errors, setErrors] = useState([]);

  const [
    forgotPassword,
    { loading: forgotPasswordLoading, data: forgotPasswordData },
  ] = useLazyQuery(FORGOT_PASSWORD);

  const [
    completeSecurityUpdate,
    {
      data: completeSecurityUpdateData,
      loading: completeSecurityUpdateLoading,
    },
  ] = useMutation(RESET_PASSWORD);

  const [
    securityUpdate,
    { loading: securityUpdateLoading, data: securityUpdateData },
  ] = useLazyQuery(SECURITY_UPDATE);

  const [
    resetPassword,
    { loading: resetPasswordLoading, data: resetPasswordData },
  ] = useMutation(RESET_PASSWORD);

  useEffect(() => {
    if (registerData) {
      const {
        register: { error, status },
      } = registerData;
      if (error && error.length) {
        if (status === 500)
          notify("an error occurred please try again later 😞😞", "error");
        else setErrors(error);
      } else {
        notify("Welcome to WRN!!", "reg.success");
        setTimeout(() => goToPath("/login?redirectUrl=welcome"), 2000);
      }
    }
  }, [registerData]);

  useEffect(() => {
    if (LoginData) {
      const {
        login: { error, token },
      } = LoginData;
      if (error) {
        notify(error[0].message, "error");
      } else
        try {
          const token_data = decodeToken(token);
          meQuery();
          localStorage.setItem("wrn_user", JSON.stringify(token_data));
          localStorage.setItem("wrn_user_dashboard_token", token);
          notify("Welcome back 👋", "reg.success");
          setTimeout(() => {
            let redirectUrl =
              new URLSearchParams(history.location.search).get("redirectUrl") ||
              "dashboard";

            redirectUrl = redirectUrl.split(".").join("/");

            goToPath("/" + redirectUrl);
          }, 2000);
        } catch (e) {
          notify(
            "An unexpected error occurred, please try again later",
            "error"
          );
        }
    }
  }, [LoginData]);

  useEffect(() => {
    if (meData) {
      const {
        me: { error, data },
      } = meData;
      if (error) {
        notify(error[0].message, "error");
        localStorage.removeItem("wrn_user");
        localStorage.removeItem("wrn_user_dashboard_token");
        setIsAuthenticated(false);
      } else {
        let newData = { ...data, role: data.role.role_id, role_: data.role };

        if (data?.profile_pic && data?.profile_pic?.blob) {
          const b64 = new Buffer(data.profile_pic.blob.data).toString("base64");
          const imageUri = `data:${data.profile_pic.mimetype};base64,${b64}`;
          newData = { ...newData, profile_pic: imageUri };
        }
        if (data?.identification && data?.identification?.blob) {
          const b64 = new Buffer(data.identification.blob.data).toString(
            "base64"
          );
          const imageUri = `data:${data.identification.mimetype};base64,${b64}`;
          newData = { ...newData, identification: imageUri };
        }
        setMe(newData);
        localStorage.setItem("wrn_user", JSON.stringify(newData));
      }
    }
  }, [meData]);

  useEffect(() => {
    if (passwordData) {
      const {
        updatePassword: { error },
      } = passwordData;
      if (error) {
        notify(error[0].message, "error");
      } else {
        notify(
          "Your account password has been updated 👍, please re-login",
          "reg.success"
        );
        setTimeout(() => quickExit(), 2000);
      }
    }
  }, [passwordData]);

  useEffect(() => {
    if (updateData) {
      const {
        updateProfile: { error },
      } = updateData;
      if (error) {
        notify(error[0].message, "error");
      } else {
        notify("Your profile has been updated 🎉🎉", "reg.success");
        setTimeout(() => window.location.reload(), 900);
      }
    }
  }, [updateData]);

  useEffect(() => {
    if (resetPasswordData) {
      const {
        forgotPasswordChange: { error, status },
      } = resetPasswordData;
      if (error && error.length) {
        if (status === 500)
          notify("an error occurred please try again later 😞😞", "error");
        else setErrors(error);
      } else {
        notify(
          "Your account password has been updated 👍, please re-login",
          "reg.success"
        );
        setTimeout(() => {
          goToPath("/login");
        }, 3000);
      }
    }
  }, [resetPasswordData]);

  useEffect(() => {
    if (securityUpdateData) {
      const {
        securityUpdate: { error, token: redirectPath, status },
      } = securityUpdateData;

      if (error && error.length) {
        if (status === 404) {
          Swal.fire({
            text:
              "Hello, this email address was not found 😞😞, please create a new account",
            icon: "error",
            showConfirmButton: false,
          }).then(() => {
            goToPath("/register");
          });
          setTimeout(() => goToPath("/register"), 2000);
        } else {
          notify("an error occurred please try again later 😞😞", "error");
          goToPath("/register");
        }
      } else setTimeout(() => goToPath(redirectPath), 750);
    }
  }, [securityUpdateData]);

  useEffect(() => {
    if (completeSecurityUpdateData) {
      const {
        forgotPasswordChange: { error, status },
      } = completeSecurityUpdateData;
      if (error && error.length) {
        if (status === 500)
          notify("an error occurred please try again later 😞😞", "error");
        else setErrors(error);
      } else {
        notify(
          "Thank you for completing the security update, you can now login 🎉🎉✨✨",
          "reg.success"
        );
        setTimeout(() => {
          goToPath("/login?redirectUrl=welcome");
        }, 2000);
      }
    }
  }, [completeSecurityUpdateData]);

  //Send Email forgotten password
  useEffect(() => {
    if (forgotPasswordData) {
      const {
        sendForgotPasswordEmail: { error },
      } = forgotPasswordData;
      if (error) {
        notify(error[0].message, "error");
      } else {
        notify(
          "Hi, please check your email to complete password update ✨✨",
          "reg.success"
        );
      }
    }
  }, [forgotPasswordData]);

  useEffect(() => {
    if (logoutData) {
      notify("bye 👋", "reg.success");
      quickExit();
    }
  }, [logoutData]);

  return (
    <AuthContext.Provider
      value={{
        setIsAuthenticated,
        me,
        meQuery,
        user,
        login,
        forgotPasswordLoading,
        forgotPassword,
        loadingLogin,
        register,
        errors,
        logout,
        logoutLoading,
        meLoading,
        registerLoading,
        isAuthenticated,
        updateUser,
        updateLoading,
        updatePassword,
        passwordLoading,
        resetPasswordLoading,
        resetPassword,
        completeSecurityUpdate,
        completeSecurityUpdateLoading,
        securityUpdate,
        securityUpdateLoading,
        securityUpdateData,
        setMe,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
