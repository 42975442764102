import styled from "styled-components";

export const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  // gap: 25px;
`;

export const Grid = styled.div`
  display: grid;
  position: relative;
  grid-template-columns: ${(props) => (props.columns || []).join(" ")};
  grid-column-gap: ${({ gap }) => gap || 0};
  height: fit-content;

  @media (max-width: 960px) {
    display: ${({ isNotResponsive }) => (isNotResponsive ? "grid" : "block")};
  }
`;

export const GridEqual = styled(Grid)`
  grid-template-columns: ${(props) => `repeat(${props.count || 3} , 1fr)`};
  grid-row-gap: ${({ gap }) => gap || 0};
`;

export const Button = styled.button`
  cursor: pointer;
  background: #27750a;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
  border: none;
  border-radius: 5px;
  color: #fff;
  padding: 0.5rem 1.4rem;
`;

export const PageHeading = styled.div`
  text-align: center;
  background: #69c448;
  color: #fff;
  width: 100%;
  padding: 2.125rem 0;
  border: none;
  border-radius: 5px;
  p {
    font-size: 1.5rem;
    font-weight: 700;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  @media (max-width: 1000px) {
    margin-top: 1rem;
  }
`;

export const FlexCenterSpaceBetween = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: ${(props) => props.marginTop || "0px"};
  flex-wrap: wrap;
  flex-grow: 1;
  @media (max-width: 1200px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 960px) {
    display: block;
  }
`;
