import React from "react";
import icons from "assets/icons/icon-collections.svg";
import logo from "assets/img/logo.svg";

import { NavLink } from "react-router-dom";

const SideNav = ({ sidebarRef, closeSidebar }) => {
  // const { isAuthenticated, signOut } = React.useContext(AuthContext);

  const sideNav = [
    {
      name: "Dashboard",
      icon: "dashboard",
      path: "/dashboard",
    },
    {
      name: "Judgment",
      icon: "judgement",
      path: "/dashboard/judgements",
      subNav: [
        { name: "Supreme Court", icon: "judgement" },
        { name: "Court of Appeal", icon: "judgement" },
      ],
    },

    {
      name: "Indexes",
      icon: "indexes",
      path: "/dashboard/indexes",
    },
    {
      name: "Civil Procedure Rules",
      icon: "procedures",
      path: "/dashboard/procedures",
    },
    {
      name: "Constitution",
      icon: "constitutions",
      path: "/dashboard/constitutions",
    },
    {
      name: "Law Dictionary",
      icon: "dictionary",
      path: "/dashboard/dictionary",
    },
    {
      name: "Laws of the Federation",
      icon: "federation",
      path: "/dashboard/federation",
    },
    {
      name: "Blogs / Articles",
      icon: "blogs",
      path: "/dashboard/blogs",
    },
    {
      name: "Notes",
      icon: "notes",
      path: "/dashboard/notes",
    },
    {
      name: "Subscriptions",
      icon: "subscriptions",
      path: "/dashboard/subscriptions",
    },
  ];

  // fill="#928989"
  return (
    <div id="sidebar" ref={sidebarRef}>
      <a href="/" id="brand">
        <img src={logo} alt="" />
      </a>
      <ul id="side-nav-items">
        {sideNav?.map((nav, i) => (
          <div key={i}>
            <li
              onClick={() => {
                closeSidebar();
              }}
            >
              <NavLink to={nav.path} activeClassName="active" exact>
                <svg height="32px" width="32px">
                  <use xlinkHref={`${icons}#${nav.icon}`} />
                </svg>
                {nav.name}
              </NavLink>
            </li>
          </div>
        ))}
      </ul>
    </div>
  );
};

export default SideNav;
