import React, { createContext } from "react";
import { useLazyQuery } from "@apollo/client";
import {
  GET_CASES_IN_A_VOLUME,
  GET_SM_IN_A_VOLUME,
  INDEXED_CASES_BY_RECENT,
  INDEXED_CASES_BY_YEAR,
  GET_VOLUMES_IN_YEAR,
  CASE_GLOSSARY,
  SUBJECT_MATTER_GLOSSARY,
  INDEXED_SM,
} from "schema/Indexes";

export const IndexesContext = createContext({});

export default function IndexesContextProvider({ children }) {
  // const [cases_recent, setRecentCases] = useState([]);

  // QUERIES + LAZY
  const [icby, { data: icbyData, loading: icbyLoading }] = useLazyQuery(
    INDEXED_CASES_BY_YEAR
  );

  const [icbr, { data: icbrData, loading: icbrLoading }] = useLazyQuery(
    INDEXED_CASES_BY_RECENT
  );

  const [ism, { data: ismData, loading: ismLoading }] = useLazyQuery(
    GET_SM_IN_A_VOLUME
  );

  const [ismy, { data: ismyData, loading: ismyLoading }] = useLazyQuery(
    INDEXED_SM
  );

  const [ivy, { data: ivyData, loading: ivyLoading }] = useLazyQuery(
    GET_VOLUMES_IN_YEAR
  );

  const [gciv, { data: gcivData, loading: gcivLoading }] = useLazyQuery(
    GET_CASES_IN_A_VOLUME
  );

  const [
    case_glossary,
    { data: caseGlossaryData, loading: caseGlossaryLoading },
  ] = useLazyQuery(CASE_GLOSSARY, {
    variables: { region: "ng" }, //   filters: -> volume || year
  });

  const [
    sm_glossary,
    { data: smGlossaryData, loading: smGlossaryLoading },
  ] = useLazyQuery(SUBJECT_MATTER_GLOSSARY, {
    variables: { region: "ng" }, //   filters: -> volume
  });
  // QUERIES + LAZY

  return (
    <IndexesContext.Provider
      value={{
        icby,
        icbyData,
        icbyLoading,
        icbr,
        icbrData,
        icbrLoading,
        ism,
        ismData,
        ismLoading,
        ismy,
        ismyData,
        ismyLoading,
        ivy,
        ivyData,
        ivyLoading,
        gciv,
        gcivData,
        gcivLoading,
        case_glossary,
        caseGlossaryData,
        caseGlossaryLoading,
        sm_glossary,
        smGlossaryData,
        smGlossaryLoading,
      }}
    >
      {children}
    </IndexesContext.Provider>
  );
}
