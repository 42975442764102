import gql from "graphql-tag";

export const REGISTER_MUTATION = gql`
  mutation register(
    $first_name: String!
    $last_name: String!
    $email: String!
    $phone_number: String!
    $role: Int!
    $password: String!
    $region: Region!
    $sex: String!
    $contactAddress: String!
    $student_type: Boolean
    $law_school: Int
    $law_universitiy: Int
    $registration_or_matriculation_num: String
    $refferal_code: String
    $short_code: String
  ) {
    register(
      first_name: $first_name
      last_name: $last_name
      region: $region
      email: $email
      phone_number: $phone_number
      role: $role
      password: $password
      refferal_code: $refferal_code
      short_code: $short_code
      sex: $sex
      contactAddress: $contactAddress
      student_type: $student_type
      law_school: $law_school
      law_universitiy: $law_universitiy
      registration_or_matriculation_num: $registration_or_matriculation_num
    ) {
      ok
      message
      status
      error {
        path
        message
      }
    }
  }
`;

export const LOGIN_MUTATION = gql`
  mutation login($user_name: String!, $password: String!, $location: String!) {
    login(user_name: $user_name, password: $password, location: $location) {
      ok
      message
      token
      status
      error {
        path
        message
      }
    }
  }
`;

export const LOGOUT_MUTATION = gql`
  mutation logout($type: String!) {
    logout(type: $type) {
      ok
      message
      status
    }
  }
`;

export const GET_USER = gql`
  {
    me {
      __typename
      ... on T_response {
        ok
        status
        message
        error {
          path
          message
        }
      }

      ... on me {
        ok
        status
        message
        data {
          user_id
          first_name
          last_name
          email
          phone_number
          user_name
          role {
            role_id
            role
          }
          refferal_code
          active
          subscribed

          picture_url
          profile_pic {
            blob
            encoding
            filename
            id
            mimetype
          }
          identification {
            blob
            encoding
            filename
            id
            mimetype
          }
          newdesignjt
          confirmed
          forgotPasswordLock

          student_type
          law_school {
            name
            location
            accreditation
          }
          law_universitiy {
            name
            location
            accreditation
          }
          uL_student_number

          region
          current_region

          active_subscriptions {
            id
            start
            end
            subscription_reference
            subscription_plan {
              subscription_plan_id
              name
              active
              amount
              description
              domain
              feature_list
              plan_code
              interval
              provider
              sub_plan_class {
                name
                description
              }
            }
          }

          subscription_plan_invoice {
            subscription_plan_invoice_id
            subscription_reference

            completed
            provider_response

            date
            region
            currency
            start
            end
            subscription_plan {
              subscription_plan_id
              active
              amount
              name
              description
              domain
              feature_list
              plan_code
              interval
              provider
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateProfile(
    $contactAddress: String
    $first_name: String
    $last_name: String
    $law_school: Int
    $law_universitiy: Int
    $picture_url: String
    $region: Region
    $registration_or_matriculation_num: String
    $role: Int
    $sex: String
    $student_type: Boolean
    $user_name: String
    $profile_pic: Upload
    $id: Upload
  ) {
    updateProfile(
      contactAddress: $contactAddress
      first_name: $first_name
      last_name: $last_name
      law_school: $law_school
      law_universitiy: $law_universitiy
      picture_url: $picture_url
      region: $region
      registration_or_matriculation_num: $registration_or_matriculation_num
      role: $role
      sex: $sex
      student_type: $student_type
      user_name: $user_name
      identification: $id
      profile_pic: $profile_pic
    ) {
      message
      ok
      status
      error {
        message
        path
      }
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  query sendForgotPasswordEmail($email: String!) {
    sendForgotPasswordEmail(email: $email) {
      error {
        message
        path
      }
      message
      ok
      status
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation forgotPasswordChange($key: String!, $newPassword: String!) {
    forgotPasswordChange(key: $key, newPassword: $newPassword) {
      error {
        message
        path
      }
      message
      ok
      status
    }
  }
`;

export const UPDATE_PASSWORD = gql`
  mutation updatePassword($oldPassword: String!, $newPassword: String!) {
    updatePassword(oldPassword: $oldPassword, newPassword: $newPassword) {
      error {
        message
        path
      }
      message
      ok
      status
    }
  }
`;

export const SECURITY_UPDATE = gql`
  query securityUpdate($email: String!) {
    securityUpdate(email: $email) {
      message
      ok
      status
      token
      error {
        message
        path
      }
    }
  }
`;
