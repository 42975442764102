import React from 'react'
import 'assets/css/lfn.css'
import { Link } from "react-router-dom";
import styled from "styled-components";
import back from 'assets/img/back-act.svg'
import right from 'assets/img/act-right.svg'
import arr from 'assets/img/arr.svg'

const Content = styled.div`
  padding: 1rem 2.375rem;
  margin: 2.3125rem 0;
  border: none;
  border-radius: 5px;

  @media (max-width: 600px) {
    padding: 1rem 1rem;
  }
`;

export default function ActSection() {
    return (
        <Content>
            <div>
                <div className="l--bet-box">
                    <div className="l--bet">
                        <p className="first-act">All</p>
                        <p>A</p>
                        <p>B</p>
                        <p>C</p>
                        <p>D</p>
                        <p>E</p>
                        <p>F</p>
                        <p>G</p>
                        <p>H</p>
                        <p>I</p>
                        <p>J</p>
                        <p>K</p>
                        <p>L</p>
                        <p>M</p>
                        <p>N</p>
                        <p>O</p>
                        <p>P</p>
                        <p>Q</p>
                        <p>R</p>
                        <p>S</p>
                        <p>T</p>
                        <p>U</p>
                        <p>V</p>
                        <p>W</p>
                        <p>X</p>
                        <p>Y</p>
                        <p>Z</p>
                    </div>
                </div>
                <Link to="/dashboard/federation/act" className="l-top-header-3">
                    <img src={back} />
                    <p className="none--phone" style={{ marginLeft: 10 }}>Act Authentication Act - 1962</p>
                    <img className="none--phone" style={{ marginLeft: 20, marginRight: 20 }} src={arr} />
                    <p className="none--phone">Section 1</p>
                </Link>
                <div className="l--content">
                    <div style={{ padding: 20 }}>
                        <p className="sec----head">Section 1 - Amendment of LN. 33 of 1978</p>
                        <div className="secs-desc">
                            <p>(1)</p>
                            <p style={{ marginLeft: 10 }}>lorem ipsum "Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, lorem ipsum "Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit...</p>
                        </div>
                        <div className="secs-desc">
                            <p>(2)</p>
                            <p style={{ marginLeft: 10 }}>lorem ipsum "Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, lorem ipsum "Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit...</p>
                        </div>
                        <div className="secs-desc">
                            <p>(3)</p>
                            <p style={{ marginLeft: 10 }}>lorem ipsum "Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, lorem ipsum "Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit...</p>
                        </div>
                    </div>
                </div>
            </div>
        </Content>
    )
}
